import { load } from 'recaptcha-v3';

function getResultUrl(result, redirectURL) {
  if (result === 'SUCCESS' && redirectURL) {
    return redirectURL;
  } else if (result === 'SUCCESS') {
    // Keep this condition to make it backward compatible for reliable deployment
    // This can be removed after the deployment
    return `${process.env.PUBLIC_URL}/confirm${window.location.search}`;
  } else if (result === 'USER_EMAIL_VALIDATION_PENDING') {
    return `${process.env.PUBLIC_URL}/confirm${window.location.search}`;
  } else if (result === 'USER_EMAIL_IN_USE') {
    return `${process.env.PUBLIC_URL}/welcome${window.location.search}`;
  } else if (result === 'ORGANISATION_DOMAIN_IN_USE') {
    return `${process.env.PUBLIC_URL}/organisation-exists${window.location.search}`;
  } else if (result === 'RECAPTCHA_FAILED') {
    return `${process.env.PUBLIC_URL}/error${window.location.search}`;
  } else if (result === 'SEND_EMAIL_FAILED') {
    return `${process.env.PUBLIC_URL}/email-error${window.location.search}`;
  } else {
    return `${process.env.PUBLIC_URL}/error${window.location.search}`;
  }
}

export function getApiUrl(path) {
  return process.env.REACT_APP_DEPLOY_ENV
    ? `https://${process.env.REACT_APP_DEPLOY_ENV}.api.legalautomation.com/registration-service/${path}`
    : `http://localhost:3000/registration-service/${path}`;
}

async function getRecaptchaToken() {
  if (window.location.hostname === 'localhost') {
    return null;
  } else {
    const recaptcha = await load('6LepS6gZAAAAADkmRlQCF4qdzLV4uxRWLsy7PQjO');
    const token = await recaptcha.execute('submit');

    return token;
  }
}

export async function submit(data) {
  try {
    const recaptchaToken = await getRecaptchaToken();
    const response = await fetch(getApiUrl('register'), {
      method: 'POST',
      body: JSON.stringify({ ...data, 'recaptcha_token': recaptchaToken }),
      headers: { 'Content-Type': 'application/json' }
    });
    const { result, redirect_url: redirectURL } = await response.json();

    if (process.env.REACT_APP_DEPLOY_ENV === "production") {
      submitGoogleEvent();
    }
    //We want to force the click on the button to flush to pendo before we reload the page
    if (window.pendo) {
      window.pendo.flushNow();  //Call the flush.
      setTimeout(() => window.location.href = getResultUrl(result, redirectURL), 500);  //Only fire this after 500ms to hopefully allow pendo to flush
    } else {
      window.location.href = getResultUrl(result, redirectURL);
    }


  } catch (e) {
    console.error(e);
    window.location.href = getResultUrl('UNKNOWN_ERROR');
  }
}

export async function submitGoogleEvent() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'generate_lead',
    'category': 'pwreg_form',
    'label': 'https://registration.plexus.co',
  });
  window.gtag('event', 'conversion', {
    'send_to': 'AW-961111800/5OPlCKyB9qcYEPjNpcoD',
    'event_callback': 'https://registration.plexus.co'
  });
  window.uetq = window.uetq || [];
  window.uetq.push('event', 'generate_lead', {
    'event_label': 'https://registration.plexus.co',
    'event_category': 'pwreg_form'
  });
  window.fbq('track', ' Complete Registration', {
    'content_category': 'pwreg_form',
    'content_name': 'https://registration.plexus.co'
  });
  window.lintrk('track', {
    conversion_id: 7897578
  });
}

export async function validateEmail(data) {
  try {
    const response = await fetch(getApiUrl('validate_email'), {
      method: 'POST',
      body: JSON.stringify({ ...data }),
      headers: { 'Content-Type': 'application/json' }
    });
    const { result } = await response.json();
    if (result !== 'SUCCESS') {
      window.location.href = getResultUrl(result);
    }
  } catch (e) {
    console.error(e);
  }
}
